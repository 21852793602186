main.products {
		
		.content {

			ul li {
				@include grid-box (3, 10px, 10px); 

				@media #{$small-only} {

					@include grid-box (1, 10px, 10px);
				}
			}
		}
		
		.product-top {
			padding-bottom: 60px;
		}

		.add-on-row {
			background-color: #50566A;
			padding: 30px 0px;
			transition:all 0.2s ease;

			img {
				display: block;
				position: relative;
				max-width: 100%;
				width: 100%;

			}

			.add-ons {
		
				@media #{$small-only} {
					margin-bottom: 60px;
					display: inline-block;
				}
			}

			.add-on-hover:hover {

				img{
					opacity: 0.5;
					transition:all 0.2s ease;
				}
			}

			.add-on-hover:hover:after {
					content: "\f067";
					font-family: fontawesome;
					font-size: 4em;
					line-height: 1em;
					color: #fff;
					position: absolute;
					top: calc(30% - .5em);
					left: calc(50% - .5em);

					@media #{$medium-only} {
						top: calc(20% - .5em);
					}
				}

			h2 {
				color: #fff;
				text-align: center;
				margin-bottom:40px;
		
			}

			h2:after {
				content: "";
				display:block ;
				background-color:#E49318;
				width: 200px;
				height: 1px; 
				position: absolute;
				left: calc(50% - 100px);
				margin-top: 10px;
			}

			.add-on-teaser {
				background-color: #fff;
				width: 100%;
				height: 200px;
				display: block;
				text-align: center;
				padding: 10px 15px;

				@media #{$medium-only} {
					height: 250px;
				}

				@media #{$small-only} {
					height: auto;
				}

				a h3 {
					color: #901d5b;
					font-size: 2em;
					line-height: 1.1em;
					margin-bottom: 2px;

					@media #{$medium-only} {
						font-size: 1.5em;
					}
				}
				
				p {
					margin-bottom: 5px;
				}

				a.learn-more:link {
					color: #50566A;
					font-style: italic;
					font-size: 1.2em;
					font-weight: lighter;
				}
			}
		}

		.product-pricing {

			margin-top: -100px;

			h2 {
				text-align: center;
				margin-bottom: 40px;
			}

			h2:after {
				content: "";
				display:block ;
				background-color:#901d5b;
				width: 200px;
				height: 1px; 
				position: absolute;
				left: calc(50% - 100px);
				margin-top: 10px;
			}

			p {
				text-align: left;

				span {
					float: right;
				}
			}

			h3 {
				text-align: left;
				background-color: #454C5F;
				color: #fff;
				width: 100%;
				padding: 10px 20px;
				padding-right:5px;
				margin-bottom: 0px;
			}

			.pricing-col {
				border: 1px solid #454C5F;
				display: block;
				padding: 10px 15px;
				position: relative;
				height: 500px;

				@media #{$medium-only} {
					height: auto;
					padding-bottom:150px;
					margin-bottom: 30px;
				}

				p {
					margin-bottom: 2px;
				}
				
				p.bold {
					margin-top: 10px;
				}

				a.button {

				}
				
				.order-button {
					width: 80%;
					margin-left: 10%;
					text-align: center;
					position: absolute;
					bottom: 40px;
					left: 0;
					
					@media #{$medium-only} {
						padding-left:25px;
						padding-right:25px;
					}
				}
				
				@media #{$small-only} {
					margin-bottom: 60px;
					height: auto;
					padding-bottom: 140px;
				}
			}
		}

	#grid-bg-element {
		background-image: url("../images/grid-bg.png");
		background-position: top right;
		margin-top: -250px;
		height: 400px;
		z-index: 5;
		background-size: cover;
		background-repeat: no-repeat;
	}
}