
	.woocommerce {


		input,
		textarea {
			margin: 0px;
			padding: 10px 15px;
			font-size: 16px;
			border: solid 1px #CBCED4;
			-webkit-box-shadow:inset 0 3px 5px 0 rgba(80,86,106,0.3);
			box-shadow:inset 0 3px 5px 0 rgba(80,86,106,0.3);
										
			&:focus {
				-webkit-box-shadow:inset 0 3px 5px 0 rgba(80,86,106,0.3), 0 0 10px 0 rgba(80,86,106,0.3);
				box-shadow:inset 0 3px 5px 0 rgba(80,86,106,0.3), 0 0 10px 0 rgba(80,86,106,0.3);
			}
		}

		label {
			margin-top: 10px;
		} 

		h3#order_review_heading {
			margin-top: 40px;
		}

		ul.woocommerce-error{

			margin-left: 0px;

			li {
				list-style: none;

				&:before {
					content: "";
				}
			}
		}

		// .quantity input {
		// 	display: none; 
		// }

		.product_meta {
			display: none;
		}
		.woocommerce-breadcrumb {
			display: none;
		}
		.woocommerce-tabs {
			display: none;
		}
		.related {
			display: none;
		}

		.product-hardware-specs {
			h2 {
				font-size: 1.2em;
				margin-bottom: 0px;
			}

			ul {
				margin-top: 0px;

				li {
					font-size: .75em;
					margin-bottom: 0px;
				}
			}


		}

		p.price {
			margin-top: 20px;
			display: inline-block;

		> span{
			font-size: 1.7em;
			font-weight: bold;
			}

		} 

		
		div#tabs {
			display:inline-block;
		}

		ul.ui-tabs-nav {

			background-color: #50566A;
			color: #fff;
			padding: 0;
			margin-bottom: 20px;
			position: relative;
			display: inline-block;
			width:100%;

			@include full-width;
			
			&:before {
				background-color: #50566A;
				z-index: 0;
			}

			li {
				background-color: #50566A;
				border-top: 2px solid #50566A;
				display: inline-block;
				border: none;
				border-radius: 0;
				text-indent: 0;
				padding: 10px 30px;
				border-left: 1px solid #fff;
				border-right: 1px solid #fff;
				margin:0;
				outline: none;

				@media #{$small-only} {
					width:100%;
					border-left: none;
					border-right: none;
					border-bottom: 1px solid #fff;
					text-align: center;

				}

				&:before {
					content: "";
					display: none;
				}

				a {
					color: #fff;

					@media #{$small-only} {
						float: none;
						width: 100%;
						text-align: center;
					}
				}

			}

		}

		ul.ui-tabs-nav li.ui-state-active {
			
			border-top: 2px solid #851C52;
			background-color: #fff;
			margin:0;
			padding: 8px 30px 10px 30px;
			outline: none;



		}

		ul.ui-tabs-nav li.ui-state-active a {
			color: #851C52;
			outline: none;

		}

		.feature-list {
			ul {
				margin-top: 0px;
				li {
					font-size: .75em;
					margin-bottom: 0px;

				@include grid-box (2, 20px, 0px);
				    
				}

			}
		}

		main .product-page-tabs { 

			width: 100%;

			h3 {
				font-size: 1.2em;
				margin-bottom: 5px;
			}
			p {
				font-size: 1em;
				line-height: 1.4em;
			}
			hr {
				margin-bottom: 40px;
				color: #D5D6D7;
			}
		}

	table.cart td.actions .coupon .input-text {
		min-width: 200px; 

		@media #{$small-only} {
			width: 100%;
		}
	}

	form.login {
   		max-width: 500px;

   		input#rememberme {
   			margin-left: 30px;
   			margin-right: 3px;
   		}

   		.lost_password {
   			margin-left: 5px;
   			margin-top: 20px;
   		}
   	}
   	
	.cart-collaterals .cart_totals th, 
	.cart-collaterals .cart_totals td {
		border: 0 !important;
		padding: 6px 5px !important;
	}
}

.single-product {
	
	#content {
		
		.images{
			padding-left: 20px;

			@media #{$medium-only} {
				padding-right:20px;
			}
			@media #{$small-only} {
				padding-right:20px;
			}

		}

		.entry-summary {
			padding-right: 20px;

			@media #{$medium-only} {
				padding-left:20px;
			}
			@media #{$small-only} {
				padding-left:20px;
			}
		}

	}
}

.ui-tabs .ui-tabs-panel {
	width:100%;
	
	.large-3 {
		text-align: center;
		
		p:empty {
			display: none;
		}
	}
}

.ui-tabs {
	@media #{$small-only} {
		padding:0 !important;
	}
}

.woocommerce-account h2 {
   		margin-left: 20px;
   	}

.woocommerce-cart, .woocommerce-checkout {
	
	main.page {
		padding-left:20px;
		padding-right:20px;
		
		.woocommerce-checkout-payment {
			background: none !important;
			
			.payment_box {
				background: none !important;
				color: #000 !important;
				
				&:before,
				&:after {
					display: none !important;
				}
			}
		}
	}
}

.woocommerce .quantity {
	width:100%;
}

// WOOCOMMERCE CART RESPONSIVE STYLES ------------------------------------------------------------

.woocommerce-page {

@media #{$small-only} {

	/* Force table to not be like tables anymore */
	table.shop_table, 
	table.shop_table thead, 
	table.shop_table tbody, 
	table.shop_table th, 
	table.shop_table td, 
	table.shop_table tr { 
		display: block; 
	}
	table.shop_table tr {
		margin-bottom: 20px;

	}


	/* Hide table headers (but not display: none;, for accessibility) */
	table.shop_table thead tr { 
		position: absolute;
		top: -9999px;
		left: -9999px;
	}

	table.shop_table tr { 
		/*border: 1px solid #d2d3d3; */
	}

	table.shop_table td { 
		/* Behave  like a "row" */
		border: 1px solid #d2d3d3; 
		border-top: none;
		position: relative;
		padding-left: 30% !important; 
	}



	table.shop_table {
		border: none;
	}

	table.shop_table td.product-spacer {
		border-color: #FFF;
		height: 10px;
	}

	table.shop_table td:before { 
		/* Now like a table header */
		position: absolute;
		/* Top/left values mimic padding */
		top: 6px;
		left: 6px;
		padding-right: 10px; 
		white-space: nowrap;
	}

	// td.product-quantity {
	// 	height:40px;
	// }

	table.cart td.actions .input-text+.button {
		float:left;
		margin-top:10px;
	}
	.woocommerce-message .wc-forward {
		width: 100%;
		float: left;
		padding-left:10px;
	}

	.woocommerce-error, .woocommerce-info, .woocommerce-message{
		padding-left:10px;
	}

	table.shop_table td.product-remove {
		border:none;
	}

	table.shop_table td.product-name {
		border: 1px solid #d2d3d3;
		padding-right: 25px; 
	}

	.woocommerce a.remove {
		position:absolute;
		right: 10px;
		top: 20px;
		z-index: 200;
		height: 1.1em;
	}

	.quantity input { 
		padding-top: 5px;
		padding-bottom: 5px;
	}
	.coupon input.button {
		margin-top: 10px;
		float: left !important;
	}

	/*
	Label the data
	*/


	// table.shop_table td.product-remove:before {
	// 	content: "DELETE";
	// }

	table.shop_table td:before {
		font-size: 14px;
	}

	table.shop_table td.product-thumbnail:before {
		content: "IMAGE";
	}

	table.shop_table td.product-name:before {
		content: "PRODUCT";
	}

	table.shop_table td.product-price:before {
		content: "PRICE";
	}

	table.shop_table td.product-quantity:before {
		content: "QUANTITY";
	}

	table.shop_table td.product-subtotal:before {
		content: "SUBTOTAL";
	}

	table.shop_table td.product-total:before {
		content: "TOTAL";
	}

	.woocommerce .quantity, 
	.woocommerce #content .quantity, 
	.quantity, 
	#content .quantity {
		margin: 0;
	}

	table.cart td.actions, 
	#content table.cart td.actions {
		text-align: left;
		border:0;
		padding-left: 0px !important;
		padding-top: 30px;
	}

	table.cart td.actions .button.alt, 
	#content table.cart td.actions .button.alt {
		float: left;
		margin-top: 10px;
	}

	table.cart td.actions div, 
	#content table.cart td.actions div,
	table.cart td.actions input, 
	#content table.cart td.actions input {
		margin-bottom: 10px;
	}

	.cart-collaterals .cart_totals {
		float: left;
		width: 100%;
		text-align: left;
	}

	.cart-collaterals .cart_totals th, 
	.cart-collaterals .cart_totals td {
		border: 0 !important;
	}

	.cart-collaterals .cart_totals table tr.cart-subtotal td,
	.cart-collaterals .cart_totals table tr.shipping td,
	.cart-collaterals .cart_totals table tr.total td {
		padding-left: 6px !important;
	}

	table.shop_table tr.cart-subtotal td,
	table.shop_table tr.shipping td,
	table.shop_table tr.total td,
	table.shop_table.order_details tfoot th,
	table.shop_table.order_details tfoot td {
		padding-left: 6px !important;
		border:0 !important;
	}

	table.shop_table tbody {
		padding-top: 10px;
	}

	.woocommerce .col2-set .col-1, 
	.col2-set .col-1,
	.woocommerce .col2-set .col-2, 
	.col2-set .col-2,
	.woocommerce form .form-row-first, 
	.woocommerce form .form-row-last, 
	form .form-row-first, 
	form .form-row-last {
		float: none;
		width: 100%;
	}

	.woocommerce .order_details ul, 
	.order_details ul,
	.woocommerce .order_details, 
	.order_details {
		padding:0;
	}

	.woocommerce .order_details li, 
	.order_details li {
		clear: left;
		margin-bottom: 10px;
		border:0;
	}
}
}
		


		

	
