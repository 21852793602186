header.desktop {
	
	background-color: #fff;
	border-bottom: solid 5px #50566a;

	@include with-touch-nav {
		display: none;
	}

	p.header-description {
		text-align: right;
		margin-top: -5px;
		margin-bottom: 5px;
		padding-right: 5px;

		@media #{$medium-only}{
			
		}

	}
	
	div.logo-area {
		padding-top: 20px;
		padding-left: 10px;
		font-size: 17px;
		font-style: italic;
		line-height: 17px;
		color: #50566a;
		max-width: 500px;
		float: left;

	}
	
	div.large-7 {
		padding-top: 18px;
	}

	nav {
		margin: 0px;
		
		&.secondary {

			float: right;
			margin-top: 20px;

			@media #{$medium-only}{
				float: left;
				clear: left;
				margin-bottom: 10px;
			}
			
			a {
				margin-left: 20px;
				font-size: 18px;
				color: #e99d18;
				text-decoration: none;
				
				&:before {
					margin-right: 8px;
					font-family: FontAwesome;
				}
				
				&.phone:before {
					content: "\f095";
				}
				
				&.email:before {
					content: "\f0e0";
				}
				&.login:before {
					content: "\f007"
				}

				
			}
		}

		&.site-icons {
			
			float: right;
			margin-top: 20px;
			padding-right: 10px;

			@media #{$medium-only}{
				margin-top: 45px;
				padding-right: 30px;
				
			}

			a {
				margin-left: 20px;
				font-size: 18px;
				color: #e99d18;
				text-decoration: none;
 
				&:before {
					margin-right: 8px;
					font-family: FontAwesome;
				}

				&.cart:before {
					content: "\f07a";
					margin-right: 0px;
				}
					
				&.facebook:before {
					content: "\f09a";
					margin-right: 0px;
				}
				
				&.search:before {
					content: "\f002";
					margin-right: 0px;
				}
			}


		}
		
		&.primary {
			margin-top: 0px;
			float: right;
			padding-right: 10px;

			@media #{$medium-only}{
				float: left;
				padding-left: 20px;
				width: 100%;
			}
			
			ul > li {
				position: relative;
				display: inline-block;
				padding-right: 35px;
				padding-top:20px;
				padding-bottom:5px;
				height: 66px;

				@media #{$medium-only} { 
					height: 40px;
					padding-top:5px;
					padding-bottom:15px;
				}
				
				&.current-menu-item,
				&.current_page_item,
				&.current-menu-ancestor,
				&.current-menu-parent,
				 {
					
					> a {
						border-bottom: solid 2px #faad16;
						text-decoration: none;
					}
				}
	
				> a {
					display: block;
					padding-bottom: 10px;
					font-size: 18px;
					color: #50566a;
					text-decoration: none;
					
					&:hover {
						border-bottom: solid 2px #faad16;
					}
				}
	
				> .sub-menu {
					position: absolute;
					top: 66px;
					left: 0;
					z-index: 99;
					padding: 0px;
					padding-top:1px;
					margin: 0px;
					background-color: #50566a;
					text-align: left;
					width: 180px; 
					display: none;

					@media #{$medium-only} { 
						top: 40px;
					}	
					
					li {
						display: block;
						margin: 0px;
						padding: 0px;
						border-bottom: solid 1px #626779;
						height: auto;
						
						a {
							margin: 0px;
							padding: 5px 10px;
							font-size: 13px;
							font-weihgt: 300;
							line-height: 18px;
							color: #fff;
							border-style: none !important;
							text-decoration: none;
						}
					}
				}
			}

			ul > li:hover > .sub-menu {
				display: block;
			}

			ul > li:last-child {
				padding-right: 0;
			}
		}
	}
}

header.mobile {
	$mobile-header-height: 46px; // Height of header
	$nav-toggle-size: 30px; // Size of hamburger
	$mobile-header-padding: #{($mobile-header-height - $nav-toggle-size) / 2};

	display: none;
	padding: $mobile-header-padding;
	width: 100%;
	height: $mobile-header-height;
	background-color: #fff;




	@include with-touch-nav {
		display: flex;
	}

	// The menu toggle button
	.toggle-off-canvas {
		display: inline-block;
		font-weight: 100;
		font-size: 1.2em;
		cursor: pointer;

		
		&::before {
			font-family: FontAwesome;
			font-size: 1.2em;
			margin-left: 0.5em;
			vertical-align: top;
			content: "\f0c9";
		}
	}

	.search-button a {
		display: inline-block;
		font-weight: 100;
		font-size: 1.2em;
		cursor: pointer;
		
		&::before {
			font-family: FontAwesome;
			font-size: 1.2em;
			margin-left: 0.5em;
			vertical-align: top;
			content: "\f002";
		}
	}

	.cart-button a {
		display: inline-block;
		font-weight: 100;
		font-size: 1.2em;
		cursor: pointer;
		
		&::before {
			font-family: FontAwesome;
			font-size: 1.2em;
			margin-left: 0.5em;
			vertical-align: top;
			content: "\f07a";
		}
	}


	.logo {
		display: inline-block;
		width: 60%;
		margin-top: 3px;
		max-width: 250px;

	}

	ul.mini-nav {
		width: 40%;
		padding-right:0px;
		display: flex;
		flex-direction: row-reverse;	

		li {
			color: #5A6175;
			font-weight: lighter;
		}
	}
}

.mobile-off-canvas {
	position: fixed;
	top: 0;
	z-index: 305;
	width: 60vw;
	min-width: 280px;
	max-width: 400px;
	height: 100vh;
	background-color: white;
	overflow: scroll;
	color: rgb(80, 86, 106);
	font-weight: bold;
	transition: left 0.5s ease-in-out;
	left: -100%;

	&.active {
		left: 0;
	}

	header {
		padding: 8px 15px;
		border-bottom: 1px solid black;
		font-size: 18px;
	}

	nav {
		padding: 0px;
		font-size: 18px;
		margin-bottom: 40px;

		li {
			display: block;
			padding:  12px 15px;
			border-bottom: 1px solid #000;

			ul.sub-menu {
				padding-left:0px;
				margin-top: 10px;

				li {
					border: none;
					text-indent: -15px; 
					font-size: 16px;
					padding: 8px 15px;
					line-height: 20px;

					&:before {
						content: "\f0da";
						font-family: fontawesome;
						margin-right: 10px;
					}
				}


			}
		}
	}
}