// Put SCSS for the front page here

#slider {
	/*
	height: 565px;
	background-image: url(../images/bg-slider.jpg);
	background-repeat: no-repeat;
	background-position: center top;
	background-size: cover;
	*/
	background-color: #50566a;
	border-bottom: solid 25px #e99d18;

	@media #{$small-only} { 
		border-bottom: solid 10px #e99d18;
	}
}
.soliloquy-container .soliloquy-image {
	width:100% !important;
}

#slider-content {

	margin-top: -260px;
	height: 260px;
	overflow: visible;
	padding-left:40px;
	 

	@media #{$small-only} { 
		padding-left: 0;
		margin-top: 0;
		height: auto;
	}

	a.call-to-action {
		@media #{$small-only} { 
			display: block;
			text-align: center;
		}
	}


	h1 {
		font-size: 3em;
		line-height: 1.1em;
		color: #ffffff;
		font-weight: 700;
		text-shadow: 1px 1px 10px rgba(0, 0, 0, 0.8);

		@media #{$medium-only} {
			font-size: 2em;
			margin-top: 80px;
		} 

		@media #{$small-only} { 

			font-size: 1.5em;
			width: 100%;
			text-align: center;
			padding-top: 10px;
			text-shadow: none;

			}
	}

	img.software-overlay {
		position: absolute;
		top:0;
		left:0;
		@media #{$medium-only} { 
			top: 60px; 
		}
		@media #{$small-only} { 
			display: none;
		}
	}

	a.call-to-action:after {
		content: "\f054";
		font-family: "FontAwesome";
		font-size: .8em;
		margin-left: 5px;


	}

	a.call-to-action {
		@media #{$small-only} { 
		margin-bottom: 20px;
		}
	}
}

#intro-1 {
	padding: 40px 0px 50px 0px;
	background-image: url(../images/ghost-hand.png);
	background-repeat: no-repeat;
	background-position: right bottom;
	background-size: 75%; 
	background-color: #f1f1f1;

	// background: #ffffff; /* Old browsers */
	// background: -moz-linear-gradient(top,  #ffffff 0%, #c9c8c8 100%); /* FF3.6+ */
	// background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#ffffff), color-stop(100%,#c9c8c8)); /* Chrome,Safari4+ */
	// background: -webkit-linear-gradient(top,  #ffffff 0%,#c9c8c8 100%); /* Chrome10+,Safari5.1+ */
	// background: -o-linear-gradient(top,  #ffffff 0%,#c9c8c8 100%); /* Opera 11.10+ */
	// background: -ms-linear-gradient(top,  #ffffff 0%,#c9c8c8 100%); /* IE10+ */
	// background: linear-gradient(to bottom,  #ffffff 0%,#c9c8c8 100%); /* W3C */
	// filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#c9c8c8',GradientType=0 ); /* IE6-9 */
	color: #50566a;
	
	h4 {
		margin-bottom: 25px;
		font-family: 'Roboto Condensed', sans-serif;
		font-size: 48px;
		font-weight: 700;
		line-height: 48px;
		color: inherit;

		@media #{$small-only} { 
			font-size: 26px;
			line-height: 30px;
		}
	}
	
	p {
		font-size: 20px;
		line-height: 28px;

		@media #{$medium-only} { 
			margin-bottom: 20px;
		}

		@media #{$small-only} { 
			margin-bottom: 20px;
		}

		
		strong {
			font-weight: 700;
		}
	}
	
	ul {
		
		li {
			position: relative;
			display: block;
			margin-bottom: 8px;
			padding-left: 28px;
			font-size: 20px;
			font-weight: 400;
			line-height: 24px;
			
			&:before {
				position: absolute;
				top: 0px;
				left: 0px;
				display: block;
				width: 24px;
				height: 24px;
				font-family: fontawesome;
				content: "\f00c";
				color: #e99d18;
			}
		}
	}
}

#intro-2 {
	margin-bottom: -45px;
	padding-top: 65px;
	background-color: #fff;
	
	h4 {
		margin-bottom: 20px;
		font-family: 'Roboto Condensed', sans-serif;
		font-size: 48px;
		font-weight: 700;
		line-height: 48px;
		color: #50566a;

		@media #{$medium-only} { 

			margin-top: 20px;

		}

		@media #{$small-only} { 
			font-size: 26px;
			line-height: 30px;
			margin-top: 20px;
		}

		&:after {
			display: block;
			padding-top: 15px;
			content: "";
			margin: 0px;
			border-bottom: solid 1px #50566a;
			width: 160px;
			height: 1px;
		}
	}

	p {
		font-size: 20px;
		line-height: 30px;
		color: #50566a;
	}

	a.button {
		margin-bottom: 30px;

		@media #{$medium-only} { 
			margin-bottom: 80px;
		}
	}
}

#intro-3 {
	padding: 60px 0px 40px 0px;
	background-image: url(../images/bg-intro-3.png);
	background-repeat: no-repeat;
	background-position: center bottom;
	background-size: cover;
	background-color: #e99d18;
	color: #fff;

	@media #{$small-only} { 
		margin-top: 40px;
		padding-top: 30px;
	}

	h4 {
		margin-bottom: 20px;
		font-family: 'Roboto Condensed', sans-serif;
		font-size: 48px;
		font-weight: 700;
		line-height: 48px;
		color: inherit;
		text-align: center;

		br {
			@media #{$medium-only} { 
				display: none;
			}
		}

		@media #{$small-only} { 
			font-size: 26px;
			line-height: 30px;
		}
	}
	
	p {
		text-transform: uppercase;
		text-align: center;
		font-size: 20px;
		font-weight: 700;
	}
	
	ul {


	
		li {
			position: relative;
			display: block;
			margin-bottom: 8px;
			padding-left: 28px;
			font-size: 20px;
			font-weight: 400;
			line-height: 24px;

			@include grid-box (2, 20px, 10px);

			@media #{$small-only} { 
				@include grid-box (1, 20px, 10px);
			}
			
			&:before {
				position: absolute;
				top: 0px;
				left: 0px;
				display: block;
				width: 24px;
				height: 24px;
				font-family: fontawesome;
				content: "\f00c";
				color: #901d5c;

				
			}
		}
	}
}

#intro-4 {
	padding: 50px 0px 50px 0px;
	background-color: #fff;

	h4 {
		margin-bottom: 20px;
		margin-top: 40px;
		font-family: 'Roboto Condensed', sans-serif;
		font-size: 48px;
		font-weight: 700;
		line-height: 48px;
		color: #50566a;

		@media #{$small-only} { 
			font-size: 26px;
			line-height: 30px;
			margin-top: 0px;
			margin-bottom: 0px;
		}

		&:after {
			display: block;
			padding-top: 15px;
			content: "";
			margin: 0px 0px 0px auto;
			border-bottom: solid 1px #50566a;
			width: 160px;
			height: 1px;

			@media #{$medium-only} {
				left: 20px;
				position: absolute;
			} 

			@media #{$small-only} { 
				display: none;
			}
		}
	}
	
	p {
		margin: 0px 0px;
		font-size: 20px;
		line-height: 30px;
		color: inherit;
	}

	.right-align {
			text-align: right;

		@media #{$medium-only} { 
			text-align: left;
		}

		@media #{$small-only} { 
			text-align: left;
		}
	}
	a.button {
		margin-bottom: 40px;
	}
}

#intro-5 {
	padding: 0px;
	background-image: url(../images/bg-intro-5.png);
	background-repeat: no-repeat;
	background-position: right bottom;
	background-color: #50566a;
	color: #fff;

	h4 {
		margin-bottom: 20px;
		font-family: 'Roboto Condensed', sans-serif;
		font-size: 48px;
		font-weight: 700;
		line-height: 48px;
		color: inherit; 

		@media #{$small-only} { 
			font-size: 36px;
		}

		&:after {
			display: block;
			padding-top: 15px;
			content: "";
			margin: 0px;
			border-bottom: solid 1px #fff;
			width: 160px;
			height: 1px;
		}
	}
	
	p {
		margin: 0px 0px 40px 0px;
		font-size: 20px;
		line-height: 30px;
		color: inherit;

		@media #{$medium-only} {
			margin-bottom: 20px;
		}
	}

	#human-assistance {
		padding: 60px 0px 0px 20px;

		@media #{$medium-only} { 
			padding-top:20px;
		}

		@media #{$small-only} { 
			padding-top:20px;
		}
	}

	a.phone-number {

		@media #{$medium-only} {
			margin-top: 10px
		} 

		@media #{$small-only} { 
			display: inline-block;
			margin-bottom: 40px;
		}
	}

	a.email-link {

		@media #{$medium-only} {
			width: 100%;
			display: inline-block;
			margin-top: 30px;
			margin-bottom: 20px;
		} 

		@media #{$small-only} { 
			display: inline-block;
			margin-bottom: 30px;
		}
		

	}

	a.phone-number:before {
		content: "\f095";
		font-family: 'FontAwesome';
		font-size: 1.5em;
		color: #e99d18;
		border: 1px solid #ffffff;
		border-radius: 50%; 
		padding: .4em .5em;
		margin-right: 10px;
		text-decoration: none;
	}

	a.email-link:before {
		content: "\f003";
		font-family: 'FontAwesome';
		font-size: 1.5em;
		color: #e99d18; 
		border: 1px solid #ffffff;
		border-radius: 50%; 
		padding: .4em .45em;
		margin-right: 10px;
		margin-left: 40px;
		text-decoration: none;

			@media #{$medium-only} {
				margin-left: 0;
			} 

			@media #{$small-only} { 
				margin-left: 0;
			}

	}
	img.full-height {
		vertical-align: top !important;
	}
}

#intro-6 {
	padding: 65px 0px 90px 0px;
	background-color: #fff;

	@media #{$small-only} { 
		padding-top:20px;
		padding-bottom: 40px;
	}

	h4 {
		margin-bottom: 20px;
		font-family: 'Roboto Condensed', sans-serif;
		font-size: 48px;
		font-weight: 700;
		line-height: 48px;
		color: #50566a;

	 	@media #{$medium-only} { 
			margin-top: 20px;
	 	}

		@media #{$small-only} { 
			font-size: 26px;
			line-height: 30px;
			margin-top: 20px;
		}

		&:after {
			display: block;
			padding-top: 15px;
			content: "";
			margin: 0px;
			border-bottom: solid 1px #50566a;
			width: 160px;
			height: 1px;
		}
	}
	
	p {
		margin: 0px 0px;
		font-size: 20px;
		line-height: 30px;
		color: #50566a;
	}
}

#news {
	padding: 40px 0px 55px 0px;
	background-color: #e3e4e7;

	h4 {
		margin-bottom: 20px;
		font-family: 'Roboto Condensed', sans-serif;
		font-size: 48px;
		font-weight: 700;
		line-height: 48px;
		color: #50566a;
		text-align: center;

		@media #{$small-only} { 
			font-size: 26px;
			line-height: 30px;
		}
		
		&:after {
			display: block;
			padding-top: 15px;
			content: "";
			margin: 0px auto;
			border-bottom: solid 1px #50566a;
			width: 160px;
			height: 1px;
		}
	}

	.home-news {
		@media #{$medium-only} { 
			margin-bottom: 40px;
		}
		@media #{$small-only} { 
			margin-bottom: 40px;
		}

		img {
			width: 100%;
		}
	}
		
	h6 {
		position: relative;
		margin-top: -43px;
		font-size: 22px;
		line-height: 40px;
		color: #fff;

		@media #{$medium-only} { 
			margin-top:-80px;
			font-size: 30px;
			line-height: 76px;
		}
		
		a {
			display: block;
			padding: 0px 12px;
			color: inherit;
			background-color: rgba(144, 29, 92, 0.8);
			text-decoration: none;
		}
	}
	
	p {
		margin: 13px 0px;
		font-size: 16px;
		line-height: 20px;
		color: #50566a;
	}
	
	a.more {
		font-size: 16px;
		font-weight: 700;
		color: #e99d18;

		
		&:after {
			margin-left: 8px;
			font-family: fontawesome;
			content: "\f105";
		}
	}
}

#testimonials {
	padding: 60px 0px 80px 0px;
	background-color: #fff;

	h4 {
		margin-bottom: 30px;
		font-family: 'Roboto Condensed', sans-serif;
		font-size: 35px;
		font-weight: 700;
		line-height: 35px;
		color: #50566a;
		text-align: center;

		@media #{$small-only} { 
			font-size: 26px;
			line-height: 30px;
		}
	}
	
	.fa-quote-left  {
		font-family: fontawesome;
		font-size: 125px;
		color: #e99d18;
	}

	blockquote {
		margin: 0px 0px 0px 0px;
		font-size: 18px;
		font-style: italic;
		line-height: 24px;
		color: #50566a;
		
		cite {
			display: block;
			margin-top: 20px;
			font-style: normal;
			
			span {
				font-weight: 700;
			}
		}
	}
	
	img {
		margin-top: -20px;
		border-radius: 50%;
		width: 160px;
		height: 160px;
		
		@media #{$small-only} { 
			margin-top: 0px;
		}
	}
}