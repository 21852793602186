 
main.main-faqs {
	
		h2 {
			color: #851C52;
			font-size: 1.5em;
			
			a:after{ 
				content: "\f0d7";
				font-family: fontawesome;
				color: #50566a;
				margin-left: 20px;
			}

			a:hover{
				text-decoration: none;
			}

			a.selected:after {
				content: "\f0da";

				
			}

		} 



		.excerpt { 
			width: 60%;
			padding: 10px 0px 40px 40px;

			p {

				border-left: 1px solid #851C52;
				line-height: 2em;
				padding-left: 20px;


			}
		} 

}

.accordion article h2 {
			color: #851C52;
			font-size: 1.5em;
			
			a#faq-label:after{ 
				content: "\f0d7";
				font-family: fontawesome;
				color: #50566a;
				margin-left: 20px;
			}

			a#faq-label:hover{
				text-decoration: none;
			}

			a#faq-label.selected:after {
				content: "\f0da";

				
			}

		}   
