body {
	margin: 0px;
	font-family: 'Roboto', sans-serif;
	font-weight: 300;
	background-color: #50566a;
	overflow-x: hidden;
}

a {
	transition: all .2s ease-in-out;
	text-decoration: none;
}
a:hover {
	text-decoration: none; 
}

a.button, a.button-alt, a:link.button, .woocommerce a.button.alt, .woocommerce button.button.alt, .woocommerce input.button.alt, .woocommerce #respond input#submit.alt {
	display: inline-block;
	margin: 10px 0px 0px 0px;
	padding: 20px 45px;
	font-size: 20px;
	font-weight: 700;
	color: #fff !important;
	background-color: #901d5c;
	text-decoration: none;
	color: #fff;
	border-radius: 0px;
	
	&:hover {
		background-color: #ac5685;
		text-decoration: none;
	}
}

a.back:before {
	content: "\f053";
	font-family: fontawesome;
	margin-right: 15px;

}

img {
	image-rendering: auto;
}

.full-width {
	width: 100%;
	max-width: none;
}
.blue-bg {
	background-color: #50566A;
}

// Gecko Modal class
ge-modal {
	display: block;
	position: fixed;

	transition: top 0.5s ease-in-out, visibility 0s linear 0.5s;
	visibility: hidden;
	top: -100%;

	&.active {
		transition-delay: 0s;
		visibility: visible;
		top: 100px;
	}

	.close::before {
		content: "\f00d";
		font-family: FontAwesome;
		position: absolute;
		top: 0;
		right: 0;
		padding: 8px;
		cursor: pointer;
		color: rgba(255, 255, 255, 0.5);
	}
}

// Blackout: used for hiding the site.
#blackout {
	position: fixed;
	width: 100vw;
	height: 100vh;
	z-index: 100;
	background-color: black;

	transition: opacity 0.5s ease-in-out, visibility 0s linear 0.5s;
	opacity: 0;
	visibility: hidden;

	&.active {
		opacity: 0.7;
		visibility: visible;
		transition-delay: 0s;
	}
}