main.testimonials {


		padding-left:20px;
		padding-right:20px;


	blockquote {

		padding-left: 120px;
		position: relative;
		margin-bottom: 40px;
		margin-top: 20px;
		display: inline-block;

		@media #{$small-only} {
			padding-left: 60px;
			}

		&:before {
			content: "\f10d";
			color: #E59318;
			font-family: fontawesome;
			font-size: 80px;
			position: absolute;
			top: 25px;
			left: 0;

			@media #{$small-only} {
			font-size: 40px;
			top: 10px;
			}

		}

		p {
			margin-bottom: 10px;
			font-style: italic;
		}

		cite {
			width: 100%;
			display: inline-block;
			font-size: .9em;
			
		}

		cite.name {
			margin-top: 20px;
			font-weight: bold;
			font-size: 1em;
			font-size: .9em;

		}

		.testimonial-image img {
			border-radius: 50%;
			width: 150px;
			height: 150px;

			@media #{$small-only} {
				margin-top: 20px;
				width: 80%;
				height: auto;
			}

		}

	}

}