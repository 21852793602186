#banner {
	padding-top: 35px;
	padding-bottom: 32px;
	padding-left: .5em;
	font-size: 40px;
	font-weight: 300;
	color: #fff;
	background: #901d5b;
	background-image: url(../images/bg-intro-5.png);
	background-repeat: no-repeat;
	background-position: right center;
	background-image: url(../images/bg-intro-5.png), -moz-linear-gradient(top, #681542 0%, #901d5b 40%, #901d5b 100%); /* FF3.6+ */
	background-image: url(../images/bg-intro-5.png), -webkit-gradient(linear, left top, left bottom, color-stop(0%,#681542), color-stop(40%,#901d5b), color-stop(100%,#901d5b)); /* Chrome,Safari4+ */
	background-image: url(../images/bg-intro-5.png), -webkit-linear-gradient(top, #681542 0%,#901d5b 40%,#901d5b 100%); /* Chrome10+,Safari5.1+ */
	background-image: url(../images/bg-intro-5.png), -o-linear-gradient(top, #681542 0%,#901d5b 40%,#901d5b 100%); /* Opera 11.10+ */
	background-image: url(../images/bg-intro-5.png), -ms-linear-gradient(top, #681542 0%,#901d5b 40%,#901d5b 100%); /* IE10+ */
	background-image: url(../images/bg-intro-5.png), linear-gradient(to bottom, #681542 0%,#901d5b 40%,#901d5b 100%); /* W3C */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#681542', endColorstr='#901d5b',GradientType=0 ); /* IE6-9 */
	
	h1 {
		color: inherit;

	}
}

main.search {
	padding-left:20px;
	padding-right: 20px;

	ol {
		li { 
		
		padding-left:50px;

		@media #{$small-only} {
			padding-left: 30px;
		}

		h2 {
			@media #{$small-only} {
				margin-bottom: 5px;
			}
		}
		
		a {
			@media #{$small-only} {
				font-size: 20px;
				line-height: 1.1em;
			}
		}

		&:before {
			font-size: 2em;
			margin-top: 6px;

			@media #{$small-only} {
				font-size: 20px;
				margin-top: 11px;
			}
		}

		}
	}
}

.page-template-default {
	.content {
		padding-right:20px;
		padding-left: 20px;
	}
}

main {
	padding-top: 65px;
	padding-bottom: 75px;
	font-weight: 300;
	font-size: 16px;
	line-height: 26px;
	color: #50566a;
	background-color: #fff;


	@media #{$small-only} {
		padding-top:40px;
	}
	
	h1 {
		margin: 0px 0px 18px 0px;
		color: inherit;
		font-family: 'Roboto Condensed', sans-serif;
		font-size: 48px;
		font-weight: 700;
		line-height: 49px;

		@media #{$small-only} {
			font-size: 36px;
		}


	}
	
	h2 {
		margin: 0px 0px 18px 0px;
		color: inherit;
		font-family: 'Roboto Condensed', sans-serif;
		font-size: 35px;
		font-weight: 700;
		line-height: 40px;
	}
	
	h3 {
		margin: 0px 0px 18px 0px;
		color: inherit;
		font-family: 'Roboto Condensed', sans-serif;
		font-size: 24px;
		font-weight: 700;
		line-height: 28px;
	}
	
	h4 {
		margin: 0px 0px 18px 0px;
		color: inherit;
		font-family: 'Roboto Condensed', sans-serif;
		font-size: 20px;
		font-weight: 700;
		line-height: 24px;
	}
	
	h5 {
		margin: 0px 0px 18px 0px;
		color: inherit;
		font-family: 'Roboto Condensed', sans-serif;
		font-size: 18px;
		font-weight: 700;
		line-height: 22px;
	}
	
	h6 {
		margin: 0px 0px 18px 0px;
		color: inherit;
		font-family: 'Roboto Condensed', sans-serif;
		font-size: 16px;
		font-weight: 700;
		line-height: 20px;
	}
	
	strong {
		font-weight: 700;
	}
	
	em {
		font-style: italic;
	}
	
	a:link,
	a:visited {
		color: #50566a;
		font-weight: 700;
		text-decoration: none;
		
		&:hover {
			color: #901d5b;
		}
	}
	
	p {
		color: inherit;
		margin-bottom: 20px;
	}
	p.bold {
		font-weight: bold;
	}
	
	ul,
	ol {
		margin-bottom: 20px;
		margin-left: 30px;
		line-height: 24px;
		
		li {
			margin-bottom: 8px;
		}
	}
	
	ul {
		margin-left: 0px;
		list-style-type: none;
		
		li {
			padding-left: 20px;
			text-indent: -20px;
			
			&:before {
				margin-right: 12px;
				font-family: fontawesome;
				content: "\f0da";
			}
		}
	}
	
	ol {
		margin-left: 0px;
		list-style-type: none;
		counter-reset: ge-ol;
		
		li {
			margin-left: 0px;
			padding-left: 30px;
			position: relative;
			
			
			&::before {
				margin-right: 5px;
				content: counter(ge-ol) ". ";
				counter-increment: ge-ol;
				font-weight: 400;
				font-family: 'Roboto Condensed', sans-serif;
				position: absolute;
				left:0;
				font-size: 1em;
			}
		}
	}
	
	blockquote {
		
		cite {
			
		}
	}
	
	.alignright {
		float: right;
		margin: 0px 0px 20px 20px;
	}
	
	.alignleft {
		float: left;
		margin: 0px 20px 20px 0px;
	}
	
	.aligncenter {
		display: block;
		margin: 0px auto 20px auto;
	}
	
	.wp-caption-text {
		margin-top: 5px;
		font-size: 15px;
		line-height: 18px;
	}

	div.gform_wrapper {
		margin: 0px;
		padding: 0px;
		
		form {
			margin: 0px;
			padding: 0px;
					
			div.gform_body {
				margin: 0px;
				padding: 0px;

				ul.gform_fields {
					margin: 0px;
					padding: 0px;
		
					li.gfield {
						margin: 0px;
						padding: 0px;
						text-indent: 0px;
						
						&:before {
							display: none;
						}
						
						&.gf_left_half {
							display: inline-block;
							margin-right: 1.3%;
							width: 48.5%;
						}
						
						&.gf_right_half {
							display: inline-block;
							width: 48.5%;
						}

						label.gfield_label {
							margin: 0px 0px 5px 0px;
							padding: 0px;
							font-size: 21px;

							span.gfield_required {
								display: none;
							}
						}
						
						div.ginput_container {
							margin: 0px 0px 20px 0px;
							padding: 0px;
							
							input,
							textarea {
								margin: 0px;
								padding: 10px 15px;
								font-size: 16px;
								border: solid 1px #CBCED4;
								-webkit-box-shadow:inset 0 3px 5px 0 rgba(80,86,106,0.3);
								box-shadow:inset 0 3px 5px 0 rgba(80,86,106,0.3);
								outline: none;
								
								&:focus {
									-webkit-box-shadow:inset 0 3px 5px 0 rgba(80,86,106,0.3), 0 0 10px 0 rgba(80,86,106,0.3);
									box-shadow:inset 0 3px 5px 0 rgba(80,86,106,0.3), 0 0 10px 0 rgba(80,86,106,0.3);
								}
							}
						}
					}
				}
			}
			
			div.gform_footer {
				margin: 0px;
				padding: 0px;

				button {
					display: inline-block;
					margin: 0px;
					padding: 20px 45px;
					font-size: 20px;
					font-weight: 700;
					color: #fff;
					background-color: #901d5c;
					outline: none;
					
					&:hover {
						background-color: #ac5685;
					}
				}
			}
		}
	}
	
	.testimonial {
		
		.fa-quote-left {
			display: block;
			margin-bottom: 0px;
			font-family: fontawesome;
			font-size: 80px;
			line-height: 80px;
			color: #e99d18;
		}
		
		blockquote {
			margin: 0px 0px 0px 0px;
			font-size: 18px;
			line-height: 24px;
			font-style: italic;
		}
			
		cite {
			display: block;
			margin-top: 30px;
			font-size: 16px;
			line-height: 24px;
			
			span {
				font-weight: 700;
			}
		}
		
		img {
			margin-top: 15px;
			border-radius: 50%;
			width: 160px;
			height: 160px;
		}
	}
}


input.search {

	margin: 0px;
    padding: 19px 25px;
    font-size: 16px;
    border: solid 1px #CBCED4;
    box-shadow: inset 0 3px 5px 0 rgba(80, 86, 106, 0);

    @media #{$small-only} {
		margin-left: 10px;
		max-width: 100%;
		margin-bottom: 20px;
    }
  }

  input.search:focus {
  	box-shadow: inset 0 3px 5px 0 rgba(80, 86, 106, 0.3), 0 0 10px 0 rgba(80, 86, 106, 0.3);
	outline: none;
  }

  .search-btn {

  	display: inline-block;
    margin: 0px;
    padding: 20px 45px;
    font-size: 20px;
    font-weight: 700;
    color: #fff;
    background-color: #901d5c;
    margin-left: 10px;

   }







