footer {
	padding: 20px 0px;
	font-size: 14px;
	color: #fff;
	text-align: center;
	position: relative;

	a#back-to-top {
		position: absolute;
		top:-15px;
		right: 5%;
		width: 100px;
		height: 75px;
		background-color: #E49318;
		color: #fff;  
		

		i {
			font-size: 3em;
			line-height: 50px;
			

		}

		@media #{$small-only} {
			top: -70px;
		}


	}
}