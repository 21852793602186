section.tour-item {

	padding-bottom: 60px;
	
	h2:after {
		content: "\f0da";
		font-family: fontawesome;
		margin-left: 20px;

	}

	.tour-left {
		padding-left: 20px;
	}



}