 
main.knowledge-base {
	
		h2 {
			color: #851C52;
			font-size: 1.5em;
			
			@media #{$medium-only} {
				line-height: 1.2em;
				margin-bottom: 30px;
			}

			@media #{$small-only} {
				line-height: 1.2em;
				margin-bottom: 30px;
			}
			
			a:after{ 
				content: "\f0d7";
				font-family: fontawesome;
				color: #50566a;
				margin-left: 20px;
			}
 
			a:hover{
				text-decoration: none;
			}

			a.selected:after {
				content: "\f0da";

				
			}

		} 



		.excerpt { 
			width: 60%;
			padding: 10px 0px 40px 40px;

			@media #{$small-only} {
				width: 100%;
				padding-left:0px;
			}

			p {

				border-left: 1px solid #851C52;
				line-height: 2em;
				padding-left: 20px;


			}
		} 

}
