main.tutorials {

	padding-left: 20px; 
	padding-right: 20px;

	h2 {
		font-size: 1.5em;

		&:before {
			content:"\f03c";
			font-family: fontawesome;
			color: #50566a;
			margin-right: 10px;
		}
	}

	@media #{$small-only} {

		input.search {
			width: 100%;
			margin-bottom: 10px;  
		}

		.search-btn {
			margin-left: 0;
		}

	}



}

.single-tutorial {
	main.single {
		padding-right:20px;
		padding-left:20px;
	}
} 

h2.tutorials-title {

	font-size: 1.5em;

		&:before {
			content:"\f03c";
			font-family: fontawesome;
			color: #50566a;
			margin-right: 10px;
		}


} 

	.alpha {
		margin: 30px 0px;
		padding: 10px 0px 10px 10px;
		border-top: solid 1px #333;
		border-bottom: solid 1px #333;
		float: left;
		width: 100%;
		
		a {
			margin-right: 15px;
			float: left;
		}
	}
